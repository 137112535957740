<template lang="pug">
  TableV2(
    :headers="headers"
    :items="items.results"
    :pageCount="items.count"
    :currentPage="items.current"
    :querySearch="params => getReportCadet(params)"
    :isLoading="isLoading"
    :actions="actions")
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  data () {
    return {
      headers: [
        { value: 'sailor.id',
          text: this.$t('tableHeaders.sailor_id'),
          sortable: false },
        { value: 'sailor.full_name_ukr',
          text: this.$t('tableHeaders.sailor_full_name'),
          sortable: false },
        { value: 'name_nz.name_ukr',
          text: this.$t('tableHeaders.nameInstitution'),
          sortable: false },
        { value: 'educ_with_dkk',
          text: this.$t('tableHeaders.decisionEKK'),
          sortable: false },
        { value: 'is_have_documents.protocol',
          text: this.$t('tableHeaders.resultEKK'),
          sortable: false },
        { value: 'is_have_documents.statement',
          text: this.$t('tableHeaders.protocolAvailability'),
          sortable: false },
        { value: 'event',
          text: this.$t('go'),
          sortable: false }
      ],
      actions: [
        { id: 1, tooltip: 'tooltip.go', action: (item) => this.goToDocumentCadet(item), color: 'blue', name: 'mdi-arrow-right', isView: true }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.report.cadets,
      isLoading: state => state.report.isLoadingReport
    })
  },
  methods: {
    ...mapActions(['getReportCadet']),
    async goToDocumentCadet (item) {
      item && this.$router.push({ path: `/sailor/${item.sailor.id}/education/student/${item.id}` })
    }
  }
}
</script>
